import React from "react";
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby";

import "@assets/scss/App.scss";

import Layout from "@components/layout";
import Header from "@components/header/Header";
import BackToolbar from "@components/modules/back-toolbar/";
import ReferEmployer from "@components/forms/refer-employer/";

import ImageTextModule from "@components/modules/image-text/";
import PopularSearch from "@components/popular-search/index";
import Footer from "@components/footer/";
import SEO from "@components/seo";

const EmployersReferEmployerPage = (props) => {
    const imgUrl = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "Candidate-Refer-A-Candidate-upload-CV.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)
    return(
        <Layout>
                <SEO
                    title="Refer an Employer | Estate Agency Recruitment Agency"
                    description="Crux Careers is a highly regarded property industry and estate agency recruitment agency.  Refer an employer and we’ll arrange for a recruitment adviser to call."
                />
                <Helmet 
                    bodyAttributes={{
                        class: 'grey-bg'
                    }}
                />
                <Header />
                <BackToolbar
                    backLinkLabel="Back"
                    showCreateAlert={false}
                />
                <ReferEmployer
                    title="Refer an Employer"
                    intro="If you know someone who needs to fill  PropTech or estate agent jobs in the UK, enter their details here."
                    formClass=" form-dark mb-34 mb-md-51 mb-xl-0"
                />
                <ImageTextModule
                    imgUrl={imgUrl.file.childImageSharp.fluid}
                    moduleData={
                        [
                            {
                                id: 1,
                                videoUrl: '',
                                Title: '',
                                Text: '',
                                Buttons: [
                                    {
                                        id: '123213',
                                        Label: 'Upload Your CV',
                                        externalLink: null,
                                        page: null,
                                        targetLink: '/candidates/upload-your-cv'
                                    }
                                ]
                            }
                        ]
                    }
                />
                <PopularSearch {...props}
                    sectionClass=" section-grey-bg"
                />
                <Footer />
        </Layout>
    )
}

export default EmployersReferEmployerPage
